import '../css/Login.css';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useState } from 'react';
import { Button } from 'primereact/button';
import BlueLogo from '../non_public_assets/d407114ac56cc86642ddd44ba7d1c086.png';
import OrangeLogo from '../non_public_assets/54633bab68c9efcae87a4ee808ccd3d9.png';

export default function Login(): React.JSX.Element {

    const [getPasswordInput, setPasswordInput] = useState<string>("");
    const [getEmailInput, setEmailInput] = useState<string>("");

    return(
        <div className='loginContainer'>
            <div className="header">
                <img src={BlueLogo} />
            </div>
            <div className="main">
                <div className='innerLoginHolder'>
                    <h1 className='loginHeader'>Login</h1>
                    <div className='loginInputs'>
                        <InputText value={getEmailInput} onChange={(e) => setEmailInput(e.target.value)} type='email' placeholder='Email' />
                        <Password value={getPasswordInput} onChange={(e) => setPasswordInput(e.target.value)} feedback={false} tabIndex={1} placeholder='Passwort' />
                    </div>

                    <div className='loginLinks'>
                        <a href='/forgot'>Passwort vergessen?</a>
                        <a href='/signup'>Benutzerkonto anlegen</a>
                    </div>
                    
                    <Button label="Anmelden" className='loginButton' />
                    
                </div>
            </div>
            <div className="footer">
                <img src={OrangeLogo} />
            </div>
        </div>
    );
}